import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import FacebookIcon from "../../svg/share/facebook.svg"
import TwitterIcon from "../../svg/share/twitter.svg"
import LinkedinIcon from "../../svg/share/linkedin.svg"
import EmailIcon from "../../svg/share/email.svg"
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"

const Share = ({ url, title, description, className }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const wrapperClass = classNames({
    "space-x-7 lg:space-x-4": true,
    [className]: className,
  })

  return (
    <div className={wrapperClass}>
      <FacebookShareButton url={url} quote={description}>
        <FacebookIcon />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon />
      </TwitterShareButton>

      <LinkedinShareButton
        url={url}
        title={title}
        summary={description}
        source={site.siteMetadata.title}>
        <LinkedinIcon />
      </LinkedinShareButton>

      <EmailShareButton url={url} subject={title} body={description}>
        <EmailIcon />
      </EmailShareButton>
    </div>
  )
}

export default Share
