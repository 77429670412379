import * as React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import Author from "./author"

const Meta = ({ category, author, date, color, className }) => {
  const wrapperClass = classNames({
    "text-gray-700": color === "gray",
    "text-gray-300": color === "light",
    [className]: className,
  })

  return (
    <div className={wrapperClass}>
      <div className="flex flex-wrap items-center">
        <Link to={`/blog/category/${category?.slug}`}>{category?.name}</Link>
        <span className="mx-2 text-blue">·</span>
        <time>{date}</time>
      </div>

      {author && <Author author={author} />}
    </div>
  )
}

Meta.defaultProps = {
  color: "light",
}
export default Meta
