import * as React from "react"
import { graphql, Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { find } from "lodash-es"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "../components/ui/grid"
import Meta from "../components/ui/meta"
import Share from "../components/ui/share"
import CodeSnippet from "../components/ui/code-snippet"
import Author from "../components/ui/author"
import { getRelatedPosts, maxChar } from "../helpers"

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <GatsbyImage
        image={node?.data?.target?.gatsbyImageData}
        alt={node?.data?.target?.title}
        className="my-12"
      />
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (
        node.content.length === 1 &&
        find(node.content[0].marks, { type: "code" })
      ) {
        return <CodeSnippet>{children}</CodeSnippet>
      }

      return <p>{children}</p>
    },
  },
}

const BlogPostTemplate = ({ data, location }) => {
  const currentURL = location.href
  const {
    id,
    title,
    publishDate,
    description,
    content,
    author,
    featuredImage,
    category,
  } = data.contentfulPost

  const shareImage = featuredImage.gatsbyImageData.images.fallback.src
  const related = getRelatedPosts(data.allContentfulPost.edges, id)

  return (
    <Layout location={location} title={title}>
      <Seo
        title={title}
        description={description || ""}
        image={shareImage}
        url={currentURL}
      />

      <header className="bg-black text-white pt-12 pb-20 lg:pt-20">
        <Container narrow>
          <Meta category={category} date={publishDate} className="mb-3" />

          <h1
            itemProp="headline"
            className="mb-6 max-w-4xl text-4xl md:text-5xl">
            {title}
          </h1>

          <Author author={author} />
        </Container>
      </header>

      <Container narrow className="mb-20 md:mb-32">
        <div className="flex flex-wrap">
          <div className="max-w-full lg:w-2/3">
            <div className="transform -translate-y-10">
              <GatsbyImage
                image={featuredImage.gatsbyImageData}
                alt={title}
                className="aspect-w-16 aspect-h-9 rounded-lg safari-overflow-fix"
              />
            </div>

            <article
              itemScope
              itemType="http://schema.org/Article"
              className="w-full overflow-hidden prose prose-lg max-w-none">
              {content && renderRichText(content, options)}
            </article>
          </div>

          <aside className="w-full pt-20 lg:pt-12 lg:pl-12 lg:w-1/3">
            <div className="sticky top-44 space-y-8">
              <div className="mb-12 text-center lg:mb-0 lg:text-left">
                <h4 className="text-xl">
                  Share<span className="text-blue">.</span>
                </h4>

                <Share
                  url={currentURL}
                  title={title}
                  description={description}
                />
              </div>

              {related.length > 0 && (
                <div className="space-y-6">
                  <div className="text-xs uppercase tracking-wider">
                    You may also like
                  </div>

                  {related.map(post => (
                    <Link
                      key={post.node.id}
                      to={`/blog/${post.node.slug}`}
                      className="flex flex-wrap items-center">
                      <div className="w-24 mr-4">
                        <GatsbyImage
                          image={post.node.featuredImage.gatsbyImageData}
                          alt={title}
                          className="aspect-w-16 aspect-h-14 rounded-lg safari-overflow-fix"
                        />
                      </div>

                      <div className="flex-1">
                        <h4 className="mb-0 text-base">
                          {maxChar(post.node.title)}
                        </h4>
                      </div>
                    </Link>
                  ))}
                </div>
              )}

              <div>
                <Link
                  to="/blog"
                  className="font-bold text-blue border-b-2 border-blue">
                  Go back to blog
                </Link>
              </div>
            </div>
          </aside>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $category: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      title
      description
      publishDate(formatString: "MMM Do, YYYY")
      category {
        name
        slug
      }
      author {
        name
        photo {
          gatsbyImageData(layout: FIXED, width: 40)
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            __typename
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      featuredImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allContentfulPost(
      filter: { category: { slug: { eq: $category } } }
      limit: 1000
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
